import React from 'react';
import './Header.css';

export default function Header() {

    return (
        <React.Fragment>
            <h1 className="header">Marvel Character Search</h1>
        </React.Fragment> 
    )
}